import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const getVendordetails = (vendorCode, token) => {
  const filter = { vendorCode: vendorCode };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/vendor?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getVentorNameDetails = async token => {
  return axios.get(`${settings.apiUrl}/api/v1/vendor`, {
    headers: {
      'erp-token': token
    }
  });
};
const getvendorByvendor = (vendorCode, token) => {
  const filter = { vendorCode: vendorCode };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/vendor?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateVendor = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/vendor/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getVendorByID = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/vendor/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updatevendordetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/vendor`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchvendorData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/vendor?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/vendor?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  getVendordetails,
  updatevendordetails,
  fetchvendorData,
  updateVendor,
  getVendorByID,
  getVentorNameDetails,
  getvendorByvendor
};
