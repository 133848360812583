import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const Addpurchase = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/purchase`, data, {
    headers: {
      'erp-token': token
    }
  });

const AddAssets = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/asset`, data, {
    headers: {
      'erp-token': token
    }
  });

const getpurchasedetails = (data, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/purchase/${data}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getpurchasedetailsbyid = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/purchase/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updatepurchasedetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/purchase/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchData = async (page, pageSize, pageFilter, token, purchaseType) => {
  const offset = page + 1;
  const baseFilter = { purchaseType };
  const serializedBaseFilter = JSON.stringify(baseFilter);

  let url = `${settings.apiUrl}/api/v1/purchase?page=${offset}&limit=${pageSize}&filter=${serializedBaseFilter}`;

  if (pageFilter.value.trim().length > 1) {
    const dynamicFilter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedDynamicFilter = JSON.stringify(dynamicFilter);
    url = `${settings.apiUrl}/api/v1/purchase?filter=${serializedDynamicFilter}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });

    const results = get(response, 'data.data.items', []) || [];
    const hasError = get(response, 'data.success');

    return {
      results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchpurchaseData = async (page, pageSize, pageFilter, token) => {
  return fetchData(page, pageSize, pageFilter, token, 'STORE');
};

const fetchpurchaseAssetData = async (page, pageSize, pageFilter, token) => {
  return fetchData(page, pageSize, pageFilter, token, 'ASSET');
};
const fetchpurchaseHKData = async (page, pageSize, pageFilter, token) => {
  return fetchData(page, pageSize, pageFilter, token, 'HK');
};

export default {
  Addpurchase,
  AddAssets,
  getpurchasedetails,
  updatepurchasedetails,
  fetchpurchaseData,
  fetchpurchaseAssetData,
  fetchpurchaseHKData,
  getpurchasedetailsbyid
};
