import axios from 'axios';
import { settings } from 'config';

const updateShiftSchedule = (id, data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/siteshift/${id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getShiftSchedule = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/siteshift/by/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  getShiftSchedule,
  updateShiftSchedule
};
