import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const addProduct = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/product`, data, {
    headers: {
      'erp-token': token
    }
  });

const getProductDetails = token => {
  return axios.get(`${settings.apiUrl}/api/v1/product`, {
    headers: {
      'erp-token': token
    }
  });
};

const updateProductDetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/product/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getProductDetailsById = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/product/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const fetchProductData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/product?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/product?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const deleteByProduct = (id, token) => {
  return axios.delete(`${settings.apiUrl}/api/v1/product/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

export default {
  addProduct,
  getProductDetails,
  fetchProductData,
  getProductDetailsById,
  updateProductDetails,
  deleteByProduct
};
