import React from 'react';
import { createRoot } from 'react-dom/client';
import { AuthProvider } from 'hooks/useAuth';
import App from './App';
import Main from './Main';
import 'helpers/initFA';

const container = document.getElementById('main');
const root = createRoot(container);

root.render(
  <AuthProvider>
    <Main>
      <App />
    </Main>
  </AuthProvider>
);
