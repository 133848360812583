import axios from 'axios';
import { settings } from 'config';

const getEmployeeDetails = (employeeId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/employee/${employeeId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getEmployeeDetailsById = (employeeId, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee/employeeNumber/${employeeId}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const removeEmployee = (employeeId, status, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee/remove/${employeeId}/${status}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const VerifiedEmployee = (employeeId, status, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee/verified/${employeeId}/${status}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getEmployeeByEmployeeNumber = (employeeNumber, token) => {
  const filter = { employeeNumber: employeeNumber, isActive: true };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/employee?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  getEmployeeDetails,
  removeEmployee,
  getEmployeeByEmployeeNumber,
  getEmployeeDetailsById,
  VerifiedEmployee
};
