import axios from 'axios';
import { settings } from 'config';

const createReport = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/report`, data, {
    headers: {
      'erp-token': token
    }
  });

const updatedReport = (id, data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/report/${id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getGeneratedReport = (month, year, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/report/${month}/${year}`, {
    headers: {
      'erp-token': token
    }
  });
};

export default {
  createReport,
  updatedReport,
  getGeneratedReport
};
