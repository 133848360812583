import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const updateTickets = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/CRM/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });
const getTicketsdetails = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/CRM/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};
const getTicket = (id, token) => {
  const defaultFilter = { assignedEmployeeId: id };
  const serializedData = JSON.stringify(defaultFilter);
  return axios.get(`${settings.apiUrl}/api/v1/CRM?filter=${serializedData}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getTickets = (id, call, token) => {
  const defaultFilter = { assignedEmployeeId: id, typeOfCall: call };
  const serializedData = JSON.stringify(defaultFilter);
  return axios.get(`${settings.apiUrl}/api/v1/CRM?filter=${serializedData}`, {
    headers: {
      'erp-token': token
    }
  });
};
const fetchImprestHolderData = async (
  page,
  pageSize,
  pageFilter,
  id,
  token
) => {
  const offset = page + 1;
  const defaultFilter = { assignedEmployeeId: id };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/CRM?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    let filter = { [`${pageFilter.key}`]: pageFilter.value };
    filter = { ...filter, assignedEmployeeId: id };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/CRM?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
const fetchImprestHolderDatas = async (
  page,
  pageSize,
  pageFilter,
  id,
  role,
  token
) => {
  const offset = page + 1;
  // const defaultFilter = { assignedEmployeeId: id, ticketType: 'INTERNAL' };
  let defaultFilter = {
    $or: [
      { assignedEmployeeId: id },
      { firstLevelEmployeeId: id },
      { secondLevelEmployeeId: id },
      { createdBy: id }
    ],
    ticketType: 'INTERNAL'
  };

  if (role === 'SUPER_ADMIN') {
    defaultFilter = {
      ticketType: 'INTERNAL'
    };
  }

  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/CRM?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    let filter = { [`${pageFilter.key}`]: pageFilter.value };
    filter = { ...filter };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/CRM?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  fetchImprestHolderData,
  updateTickets,
  getTicketsdetails,
  getTicket,
  getTickets,
  fetchImprestHolderDatas
};
