import axios from 'axios';
import { settings } from 'config';

const getEmployeeBySite = (token, siteId) => {
  const filter = { siteCode: siteId, isActive: true };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/employee?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
/* const getEmployeeBySitecode = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const getEmployeeBySitecodeAndEmployee = (employeeId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/byEmployee/${employeeId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
}; */

const getOtherDeductions = (employeeIDNumber, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/otherdeduction/byMonthReport/${employeeIDNumber}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  getEmployeeBySite,
  // getEmployeeBySitecode,
  // getEmployeeBySitecodeAndEmployee,
  getOtherDeductions
};
