import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

// Get all the Stocks Details from the API
const getManualattendancedetails = token => {
  return axios.get(`${settings.apiUrl}/api/v1/manualAttendace`, {
    headers: {
      'erp-token': token
    }
  });
};

const getManualattendancedetailsUnitcode = (
  token,
  formattedDate,
  currentSiteId
) => {
  const filters = {
    siteId: currentSiteId,
    month: formattedDate
  };
  const queryString = JSON.stringify(filters);
  if (typeof queryString === 'string') {
    const trimmedValue = queryString.trim();
    return axios.get(
      `${settings.apiUrl}/api/v1/manualAttendace?filter=${trimmedValue}`,
      {
        headers: {
          'erp-token': token
        }
      }
    );
  }
};

const getAttendanceByEmployee = (
  todayYears,
  todayMonth,
  siteIdss,
  days,
  token
) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/bySiteAttendance/${days}/${siteIdss}/${todayMonth}/${todayYears}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const fetchManualattendanceData = async (
  page,
  pageSize,
  pageFilter,
  // month,
  todayYears,
  todayMonth,
  token
) => {
  const offset = page + 1;
  const monthFilter = { month: `${todayMonth}-${todayYears}` };
  const serializedData = JSON.stringify(monthFilter);
  let url = `${settings.apiUrl}/api/v1/manualAttendace?page=${offset}&limit=${pageSize}&filter=${serializedData}`;

  if (
    typeof pageFilter.value === 'string' ||
    typeof pageFilter.value === 'number'
  ) {
    const filterValue = pageFilter.value ? pageFilter.value.trim() : '';
    if (filterValue) {
      const filter = {
        [`${pageFilter.key}`]: filterValue,
        month: `${todayMonth}-${todayYears}`
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
    }
  } else if (
    typeof pageFilter.value === 'object' &&
    'employeeNumber' in pageFilter.value
  ) {
    if (pageFilter.value.employeeNumber) {
      const filter = {
        [`${pageFilter.key}`]: pageFilter.value.employeeNumber,
        month: `${todayMonth}-${todayYears}`
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
    }
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const bulkfetchManualattendanceData = async (
  page,
  pageSize,
  pageFilter,
  // month,
  todayYears,
  todayMonth,
  token
) => {
  const offset = page + 1;

  const defaultFilter = {
    isBulkDuty: true,
    month: `${todayMonth}-${todayYears}`
  };

  // const monthFilter = { month: `${todayMonth}-${todayYears}` };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/manualAttendace?page=${offset}&limit=${pageSize}&filter=${serializedData}`;

  if (
    typeof pageFilter.value === 'string' ||
    typeof pageFilter.value === 'number'
  ) {
    const filterValue = pageFilter.value ? pageFilter.value.trim() : '';
    if (filterValue) {
      const filter = {
        [`${pageFilter.key}`]: filterValue,
        month: `${todayMonth}-${todayYears}`,
        isBulkDuty: true
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
    }
  } else if (
    typeof pageFilter.value === 'object' &&
    'employeeNumber' in pageFilter.value
  ) {
    if (pageFilter.value.employeeNumber) {
      const filter = {
        [`${pageFilter.key}`]: pageFilter.value.employeeNumber,
        month: `${todayMonth}-${todayYears}`,
        isBulkDuty: true
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
    }
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const fetchYourManualattendanceData = async (
  page,
  pageSize,
  pageFilter,
  id,
  todayYears,
  todayMonth,
  token
) => {
  const offset = page + 1;
  const defaultFilter = {
    operationManagerId: id,
    month: `${todayMonth}-${todayYears}`
  };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}&page=${offset}&limit=${pageSize}`;
  console.log(pageFilter.value);

  if (
    (typeof pageFilter.value === 'string' ||
      typeof pageFilter.value === 'number') &&
    String(pageFilter.value).trim().length > 0
  ) {
    let filter = {
      [`${pageFilter.key}`]: pageFilter.value,
      month: `${todayMonth}-${todayYears}`
    };
    filter = { ...filter, operationManagerId: id };
    console.log(filter);
    const serializedData = JSON.stringify(filter);
    console.log(serializedData);
    url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
  } else if (
    typeof pageFilter.value === 'object' &&
    'employeeNumber' in pageFilter.value
  ) {
    if (pageFilter.value.employeeNumber) {
      const filter = {
        [`${pageFilter.key}`]: pageFilter.value.employeeNumber,
        month: `${todayMonth}-${todayYears}`
      };
      const serializedData = JSON.stringify(filter);
      url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
    }
  }

  // if (!pageFilter.value.trim().length > 1) {
  //   let filter = { [`${pageFilter.key}`]: pageFilter.value };
  //   filter = { ...filter, operationManagerId: id };
  //   console.log(filter);
  //   const serializedData = JSON.stringify(filter);
  //   console.log(serializedData);
  //   url = `${settings.apiUrl}/api/v1/manualAttendace?filter=${serializedData}`;
  // }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  getManualattendancedetails,
  fetchManualattendanceData,
  fetchYourManualattendanceData,
  getManualattendancedetailsUnitcode,
  bulkfetchManualattendanceData,
  getAttendanceByEmployee
};
