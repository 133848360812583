import axios from 'axios';
import { settings } from 'config';

const getTodayAttendance = (siteId, date, month, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/by/${siteId}/${date}/${month}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const addTodayAttendance = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/employee-attendace`, data, {
    headers: {
      'erp-token': token
    }
  });

const addTodayNewAttendance = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/employee-attendace/add`, data, {
    headers: {
      'erp-token': token
    }
  });

const updateAttendance = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/manualAttendace/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });
const getAttendancedetails = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/manualAttendace/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updateAttendanceByEmployee = (employeeNumber, data, token) =>
  axios.post(
    `${settings.apiUrl}/api/v1/employee-attendace/update/${employeeNumber}`,
    data,
    {
      headers: {
        'erp-token': token
      }
    }
  );

const getAttendanceReport = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/bySite/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const getAttendanceReportByEmployee = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/bySiteAttendance/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const getDailyBreakUp = (currentMonth, currentYear, currentSiteId, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/siteAttendanceByRole/${currentSiteId}/${currentMonth}/${currentYear}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};
const getSiteAttendanceReport = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/bySiteAttendance/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const EmployeeInfoData = (token, employeeId, currentmonth) => {
  const filter = { employeeIDNumber: employeeId, month: currentmonth };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/salary?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getYourAttendanceReport = (employeeId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/byEmployee/${employeeId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getAttendanceReportForAllSite = (date, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/allSite/${date}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getAttendanceReportForInvoice = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/employee-attendace/byInvoice/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

export default {
  getTodayAttendance,
  addTodayAttendance,
  addTodayNewAttendance,
  updateAttendanceByEmployee,
  getAttendanceReport,
  getAttendanceReportForAllSite,
  getYourAttendanceReport,
  updateAttendance,
  getAttendancedetails,
  getAttendanceReportForInvoice,
  getSiteAttendanceReport,
  getAttendanceReportByEmployee,
  getDailyBreakUp,
  EmployeeInfoData
};
