/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Button, Col, Row, Space, Spin } from 'antd';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
  getDaysInMonth,
  getPreviousMonthNames,
  monthNames
} from 'helpers/utils';
import useAPI from 'hooks/useApi';
import { get, isEmpty } from 'lodash';
import siteAPI from 'api/siteCreation';
import attendanceAPI from 'api/attendance';
import { useAuth } from 'hooks/useAuth';
import AttendanceReports from './AttendanceReports';
import { toast } from 'react-toastify';
import IconButton from 'components/common/IconButton';
import { Select } from 'antd';
import { Card } from 'react-bootstrap';
import Loading from './Loading';

const AttendanceReportsContainer = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const todayDate = new Date();
  const monthNameList = getPreviousMonthNames(todayDate.getFullYear());
  const [monthOptions, setMonthOptions] = useState(monthNameList);
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const getSiteAPI = useAPI(siteAPI.getSitedetailsBySiteCode);
  const [siteData, setSiteData] = useState([]);
  const [loading, setLoading] = useState(true);
  const yearOptions = [
    {
      value: 2022,
      label: '2022'
    },
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    }
  ];

  const dayColumn = getDaysInMonth(currentMonth, currentYear);
  const defaultColumn = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
      key: 'name',
      width: 200
    },
    {
      title: 'EMP Id',
      dataIndex: 'id',
      fixed: 'left',
      key: 'empid',
      width: 100
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      editable: true,
      fixed: 'left',
      width: 120
    }
  ];

  const totalColumn = [
    {
      title: 'Present ',
      dataIndex: 'present',
      key: 'Present',
      width: 100
    },
    {
      title: 'Absent',
      dataIndex: 'absent',
      key: 'absent',
      width: 100
    },
    {
      title: 'WeekOff',
      dataIndex: 'weekOff',
      key: 'weekOff',
      width: 100
    },
    {
      title: 'National Holiday',
      dataIndex: 'nationalHoliday',
      key: 'nationalHoliday',
      width: 100
    },
    {
      title: 'Bulk Duty',
      dataIndex: 'bulkDuty',
      key: 'bulkDuty',
      width: 100
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100
    }
  ];

  const siteIdsString = window.localStorage.getItem('siteIds');
  const siteIds = siteIdsString ? JSON.parse(siteIdsString) : [];
  let siteIdsOptions = [];
  siteIds.map(item => {
    const siteIdArray = item.split('-');
    siteIdsOptions.push({
      value: siteIdArray[0],
      label: item
    });
  });
  siteIdsOptions.push({ value: 'ALL', label: 'ALL' });
  let userSiteIds = get(user, 'unitCodes');
  let userDefaultSiteId = '';
  if (userSiteIds && userSiteIds[0] === 'ALL') {
    userDefaultSiteId = get(siteIdsOptions[0], 'value', '');
  } else if (userSiteIds && userSiteIds.length > 0) {
    siteIdsOptions = siteIdsOptions.filter(item =>
      userSiteIds.includes(item.value)
    );
    userDefaultSiteId = userSiteIds[0];
  }
  const [currentSiteId, setCurrentSiteId] = useState(userDefaultSiteId);

  const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
  const [tableColumn, setTableColumn] = useState(columns);
  const handleChangeYear = value => {
    if (value === todayDate.getFullYear()) {
      setCurrentMonth(todayDate.getMonth());
    }
    setCurrentYear(value);
    setTimeout(() => {
      getAttendanceReport(currentSiteId, currentMonth + 1, value);
    }, 500);
  };
  const handleChangeMonth = value => {
    setCurrentMonth(value);
    setTimeout(() => {
      getAttendanceReport(currentSiteId, value + 1, currentYear);
    }, 500);
  };

  const handleChangeSiteId = value => {
    setCurrentSiteId(value);
    getSiteDetails(value);
    setTimeout(() => {
      getAttendanceReport(value, currentMonth + 1, currentYear);
    }, 500);
  };

  useEffect(() => {
    const monthNameList = getPreviousMonthNames(currentYear);
    const dayColumn = getDaysInMonth(currentMonth, currentYear);
    const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
    setTableColumn(columns);
    setMonthOptions(monthNameList);
  }, [currentYear]);

  useEffect(() => {
    const dayColumn = getDaysInMonth(currentMonth, currentYear);
    const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
    setTableColumn(columns);
  }, [currentMonth]);

  const getSiteDetails = siteId => {
    getSiteAPI.request(siteId, get(user, 'token'));
  };

  useEffect(() => {
    setLoading(true);
    getSiteDetails(currentSiteId);
    const month = todayDate.getMonth() + 1;
    getAttendanceReport(currentSiteId, month, todayDate.getFullYear());
  }, []);

  useEffect(() => {
    if (getSiteAPI.data) {
      if (!isEmpty(getSiteAPI.data.data)) {
        setSiteData(getSiteAPI.data.data);
      }
    }
    setLoading(false);
  }, [getSiteAPI.data]);

  useEffect(() => {
    if (getSiteAPI.error) {
      setSiteData({});
    }
    setLoading(false);
  }, [getSiteAPI.error]);

  const style = {
    padding: '8px 0'
  };
  // const [countData, setCountData] = useState({});
  // const attendanceCount = todayAttendance => {
  //   const totalCountsByName = {};
  //   todayAttendance.forEach(item => {
  //     const { name } = item;
  //     totalCountsByName[name] = totalCountsByName[name] || 0;
  //     for (let i = 1; i <= 31; i++) {
  //       totalCountsByName[name] += item[`day${i}`] || 0;
  //     }
  //   });
  //   const formattedData = {};
  //   for (const name in totalCountsByName) {
  //     formattedData[name] = `${totalCountsByName[name]}`;
  //   }
  //   setCountData(formattedData);
  // };

  const getAttendanceReport = (siteId, month, year) => {
    setLoading(true);
    attendanceAPI
      .getAttendanceReport(siteId, month, year)
      .then(response => {
        const todayAttendance = get(response, 'data.data', []);
        setData(todayAttendance);
        // attendanceCount(todayAttendance);
        setLoading(false);
      })
      .catch(() => {
        toast.error(`Get Attendance failed!`, {
          theme: 'colored'
        });
        setLoading(false);
      });
  };

  const onSearch = value => {
    console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const siteName = get(siteData, 'siteName', '');
  const siteCode = get(siteData, 'unitcode', '');
  const siteNameDetails = `${siteCode} - ${siteName}`;
  const isAdmin = user.role !== 'FM';

  const onClickExportAsExcel = () => {
    const currentMonthName = monthNames[currentMonth].label;
    const dataToExport = data.map(record =>
      tableColumn.map(column => get(record, column.dataIndex, ''))
    );

    const worksheet = XLSX.utils.aoa_to_sheet([
      tableColumn.map(column => column.title),
      ...dataToExport
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, currentMonthName);

    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });

    saveAs(blob, 'AttendanceData.xlsx');
  };

  if (loading) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <>
      <Loading visible={loading} />
      <Card>
        <Card.Header className="bg-shape modal-shape-header px-4 position-relative">
          <div className="position-relative z-index-1 light">
            <h5 className="mb-0 text-white">All Attendances Report</h5>
          </div>
        </Card.Header>
        <Row align="middle">
          <Col span={isAdmin ? 8 : 18}>
            <h5>{siteNameDetails} </h5>
          </Col>
          <Col style={style} span={isAdmin ? 15 : 5} offset={1}>
            <Space wrap>
              {isAdmin && (
                <Select
                  defaultValue={currentSiteId}
                  showSearch
                  placeholder="Select a site"
                  optionFilterProp="children"
                  onChange={handleChangeSiteId}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={siteIdsOptions}
                  style={{
                    width: 410
                  }}
                />
              )}
              <Select
                defaultValue={currentYear}
                style={{
                  width: 120
                }}
                onChange={handleChangeYear}
                options={yearOptions}
              />
              <Select
                defaultValue={currentMonth}
                value={currentMonth}
                style={{
                  width: 120
                }}
                onChange={handleChangeMonth}
                options={monthOptions}
              />
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </div>
              <div id="orders-actions">
                <Button
                // variant="primary"
                // size="sm"
                // icon="external-link-alt"
                // transform="shrink-3"
                // onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    Total No.of Employees count : {data.length}
                  </span>
                </Button>
              </div>
            </Space>
          </Col>
        </Row>
        <Row>
          <AttendanceReports
            columns={tableColumn}
            tableData={data}
            month={currentMonth}
            year={currentYear}
            hide={false}
          />
        </Row>
      </Card>
    </>
  );
};
export default AttendanceReportsContainer;
