import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const getAssetsId = token => {
  return axios.get(`${settings.apiUrl}/api/v1/asset/allAssetIds`, {
    headers: {
      'erp-token': token
    }
  });
};
const AddAssetsMovementRegister = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/assetmovement`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchAssetMoventRegisterData = async (
  page,
  pageSize,
  pageFilter,
  token
) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/assetmovement?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/cashholder?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  getAssetsId,
  AddAssetsMovementRegister,
  fetchAssetMoventRegisterData
};
