import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const AddOtherDeductuion = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/otherdeduction`, data, {
    headers: {
      'erp-token': token
    }
  });
const getOtherDeductuiondetails = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/otherdeduction/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const deleteOtherDeductuion = (id, token) => {
  return axios.delete(`${settings.apiUrl}/api/v1/otherdeduction/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updateOtherDeductuiondetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/otherdeduction/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchOtherDeductuionData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  const defaultFilter = { from: null, to: null };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/otherdeduction?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      filter = { ...filter, [`${pageFilter.key}`]: pageFilter.value };
    }
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/otherdeduction?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  AddOtherDeductuion,
  getOtherDeductuiondetails,
  updateOtherDeductuiondetails,
  fetchOtherDeductuionData,
  deleteOtherDeductuion
};
