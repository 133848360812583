import axios from 'axios';
import { get, trim } from 'lodash';
import { settings } from 'config';

const AddSubContractorTransaction = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/subcontractor`, data, {
    headers: {
      'erp-token': token
    }
  });

const getSubTransdetails = (subId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/subcontractor/${subId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getSubContractorReport = (month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/impresttransaction/subcontractor/report/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateSubTransdetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/subcontractor/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchSubContractorTransactionData = async (
  page,
  pageSize,
  pageFilter,
  token
) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/subcontractor?page=${offset}&limit=${pageSize}`;

  if (trim(pageFilter.key).length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/subcontractor?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  AddSubContractorTransaction,
  fetchSubContractorTransactionData,
  getSubTransdetails,
  updateSubTransdetails,
  getSubContractorReport
};
