/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from 'react';
import {
  Col,
  Row,
  Space,
  Select,
  Button,
  Spin,
  Checkbox,
  notification,
  Input
} from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { Tag } from 'antd';
import { transectionType } from 'helpers/utils';
import { getMonthNames, monthNames } from 'helpers/utils';
import * as XLSX from 'xlsx';
import Highlighter from 'react-highlight-words';
import ImprestHolderTransactionAPI from 'api/Imprestholdertransaction';
import { saveAs } from 'file-saver';
import { get, upperCase } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import IconButton from 'components/common/IconButton';
import Loading from 'components/attendance/Loading';
import { useNavigate } from 'react-router-dom';
import { Card } from 'react-bootstrap';
import { getErrorMessage } from 'helpers/utils';
import expensesApi from 'api/expenses';
import AccountsTransactionListTable from './AccountsTransactionListTable';
import { toast } from 'react-toastify';

const AccountsTransactionList = () => {
  const { user } = useAuth();
  const [data, setData] = useState([]);
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (type, message) => {
    api[type]({
      message: message
    });
  };
  const todayDate = new Date();
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const [modalVisible, setModalVisible] = useState(true);
  const [salaryPaid, setSalaryPaid] = useState(false);
  const [salaryNotPaid, setSalaryNotPaid] = useState(false);
  const [salaryHold, setSalaryHold] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowKey, setSelectedRowKey] = useState([]);
  const [expenses, setExpenses] = useState([]);

  useEffect(() => {
    getSalaryReport(currentMonth, currentYear);
  }, [currentMonth, currentYear, salaryNotPaid, salaryPaid, salaryHold]);

  const getSalaryReport = (month, year) => {
    // console.log(`Month${monthNames(month).}-${year}`);

    setModalVisible(true);
    let status = '';
    if (salaryPaid) {
      status = 'PAID';
    }
    if (salaryNotPaid) {
      status = 'NOT_PAID';
    }
    const token = 'token';
    ImprestHolderTransactionAPI.fetchAccountsDetails(token, status, month, year)
      .then(response => {
        const responseData = get(response, 'data.data.items', []);
        const mapData = responseData.map(item => {
          return { ...item, key: item.id };
        });
        setData(mapData);
        setModalVisible(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setModalVisible(false);
      });
  };

  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = clearFilters => {
    clearFilters();
    setSearchText('');
  };
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close
    }) => (
      <div
        style={{
          padding: 8
        }}
        onKeyDown={e => e.stopPropagation()}
      >
        {dataIndex === 'payType' ? (
          <Select
            showSearch
            style={{ width: '100%', marginBottom: 8 }}
            size="large"
            placeholder="Select a type"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={value => {
              setSelectedKeys(value ? [value] : []);
              handleSearch([value], confirm, dataIndex);
            }}
            options={transectionType}
          />
        ) : dataIndex === 'expensesType' ? (
          <Select
            showSearch
            style={{ width: '100%', marginBottom: 8 }}
            size="large"
            placeholder="Select an expense type"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            onChange={value => {
              setSelectedKeys(value ? [value] : []);
              handleSearch([value], confirm, dataIndex);
            }}
            options={assignedForOptions}
          />
        ) : (
          <Input
            ref={searchInput}
            placeholder={`Search ${dataIndex}`}
            value={selectedKeys[0]}
            onChange={e =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              marginBottom: 8,
              display: 'block'
            }}
          />
        )}
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90
            }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => (
      <SearchOutlined
        style={{
          color: filtered ? '#1677ff' : undefined
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      )
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await expensesApi.getAllExpenses(get(user, 'token'));
      const errorMessage = getErrorMessage(response);
      if (errorMessage) {
        toast.error(errorMessage, {
          theme: 'colored'
        });
      } else {
        setExpenses(get(response.data, 'data', []));
      }
    };
    fetchData();
  }, []);
  const assignedForOptions = [];
  const roles = expenses ? expenses : [];
  roles.map(item => {
    assignedForOptions.push({
      value: item,
      label: item
    });
  });

  const totalColumn = [
    {
      title: 'UNITCODE',
      dataIndex: 'unitCode',
      key: 'unitCode',
      width: 120,
      ...getColumnSearchProps('unitCode')
    },
    {
      title: 'ROOM CODE',
      dataIndex: 'roomCode',
      key: 'roomCode',
      width: 200
    },
    {
      title: 'VENDOR CODE',
      dataIndex: 'vendorCode',
      key: 'vendorCode',
      width: 200,
      ...getColumnSearchProps('vendorCode')
    },
    {
      title: 'DEBIT',
      dataIndex: 'debit',
      key: 'debit',
      width: 150
    },
    {
      title: 'CREDIT',
      dataIndex: 'credit',
      key: 'credit',
      width: 150
    },
    {
      title: 'TRANSACTION TYPE',
      dataIndex: 'payType',
      key: 'payType',
      width: 250,
      ...getColumnSearchProps('payType')
    },
    {
      title: 'EXPENSES TYPE',
      dataIndex: 'expensesType',
      key: 'expensesType',
      width: 250,
      ...getColumnSearchProps('expensesType')
    },
    {
      title: 'REMARKS',
      dataIndex: 'explanation',
      key: 'explanation',
      width: 150,
      ...getColumnSearchProps('explanation')
    },
    {
      title: 'STATUS',
      dataIndex: 'status',
      key: 'status',
      width: 150,
      render: status => (
        <Tag
          color={
            status === 'PAID'
              ? '#87d068'
              : status === 'HOLD'
              ? '#108ee9'
              : '#f50'
          }
        >
          {status || 'NOT_PAID'}
        </Tag>
      )
    }
  ];

  const handleUnMarkPaid = async () => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon('error', 'Please select at least one employee.');
    } else {
      const response = await ImprestHolderTransactionAPI.updateAccountsDetails(
        selectedRowKey,
        'NOT_PAID'
      );
      const errorMessage = getErrorMessage(response);
      if (errorMessage) {
        openNotificationWithIcon('error', 'Error.');
      } else {
        setSelectedRows([]);
        getSalaryReport();
      }
    }
  };

  const handleMarkPaid = async () => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon('error', 'Please select at least one employee.');
    } else {
      const response = await ImprestHolderTransactionAPI.updateAccountsDetails(
        selectedRowKey,
        'PAID'
      );
      const errorMessage = getErrorMessage(response);
      if (errorMessage) {
        openNotificationWithIcon('error', 'Error.');
      } else {
        setSelectedRows([]);
        getSalaryReport();
      }
    }
  };

  const defaultColumn = [
    {
      title: 'S.NO',
      dataIndex: 'sno',
      fixed: 'left',
      key: '',
      width: 100,
      render: (text, record, index) => index + 1
    },
    {
      title: 'DATE',
      dataIndex: 'date',
      key: 'date',
      editable: true,
      fixed: 'left',
      width: 200,
      render: date => {
        const saleDate = new Date(date);
        const month = saleDate.getMonth() + 1;
        const monthValue = month < 10 ? `0${month}` : `${month}`;
        const day = saleDate.getDate();
        const dayValue = day < 10 ? `0${day}` : `${day}`;
        const formatDate = `${dayValue}/${monthValue}/${saleDate.getFullYear()}`;
        return <>{formatDate}</>;
      }
    },
    {
      title: 'EMP NAME',
      dataIndex: 'employeeName',
      key: 'employeeName',
      editable: true,
      fixed: 'left',
      width: 200
    },
    {
      title: 'EMP ID',
      dataIndex: 'employeeID',
      key: 'employeeID',
      editable: true,
      fixed: 'left',
      width: 120,
      ...getColumnSearchProps('employeeID')
    }
  ];
  const columns = [...defaultColumn, ...totalColumn];
  const navigate = useNavigate();

  const onClickExportAsExcel = () => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon('error', 'Please select at least one employee.');
    } else {
      const currentMonthName = monthNames[currentMonth].label;
      const dataToExport = selectedRows.map(record =>
        columns.map(column => get(record, column.dataIndex, ''))
      );

      const worksheet = XLSX.utils.aoa_to_sheet([
        columns.map(column => column.title),
        ...dataToExport
      ]);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, currentMonthName);

      const excelBuffer = XLSX.write(workbook, {
        bookType: 'xlsx',
        type: 'array'
      });
      const blob = new Blob([excelBuffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      });

      saveAs(blob, 'Salary.xlsx');
    }
  };

  const onChangeSalaryPaid = e => {
    const isChecked = e.target.checked;
    if (e.target.checked) {
      setSalaryPaid(isChecked);
      setSalaryNotPaid(!isChecked);
      setSalaryHold(!isChecked);
    } else {
      setSalaryPaid(false);
    }
  };

  const onChangeSalaryNotPaid = e => {
    const isChecked = e.target.checked;
    if (e.target.checked) {
      setSalaryNotPaid(isChecked);
      setSalaryPaid(!isChecked);
      setSalaryHold(!isChecked);
    } else {
      setSalaryNotPaid(false);
    }
  };

  const style = {
    padding: '8px 0'
  };

  if (modalVisible) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }

  const downloadTxtFile = () => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon('error', 'Please select at least one employee.');
    } else {
      const currentMonthName = monthNames[currentMonth].label;
      const monthChar = upperCase(currentMonthName.slice(0, 3));
      const yearChar = currentYear.toString().slice(2);
      const texts = selectedRows
        .filter(item => get(item, 'bankDetails.bankName') !== 'CITY UNION BANK')
        .map(
          item =>
            `NEFT~${get(item, 'bankDetails.ifscode')}~${item.debit}.00~10~${get(
              item,
              'bankDetails.accountNumber'
            )}~${item.employeeName}~CHENNAI~SAL-${monthChar}-${yearChar}`
        );
      const file = new Blob([texts.join('\n')], { type: 'text/plain' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      element.download = `Other Bank-${formattedDate}.txt`;
      document.body.appendChild(element);
      element.click();
    }
  };

  const downloadTxt = () => {
    if (selectedRows.length === 0) {
      openNotificationWithIcon('error', 'Please select at least one employee.');
    } else {
      const currentMonthName = monthNames[currentMonth].label;
      const monthChar = upperCase(currentMonthName.slice(0, 3));
      const yearChar = currentYear.toString().slice(2);
      const texts = selectedRows
        .filter(item => get(item, 'bankDetails.bankName') === 'CITY UNION BANK')
        .map(
          item =>
            `${get(item, 'bankDetails.accountNumber')}~${
              item.debit
            }.00~SAL-${monthChar}-${yearChar}`
        );
      const file = new Blob([texts.join('\n')], { type: 'text/plain' });
      const element = document.createElement('a');
      element.href = URL.createObjectURL(file);
      element.download = 'CITY_UNION_BANK_' + Date.now() + '.txt';
      document.body.appendChild(element);
      element.click();
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      const items = selectedRows.map(item => item.id);
      setSelectedRowKey(items);
    }
  };

  const handleChangeYear = value => {
    setCurrentYear(value);
    // console.log(value);
    // setSelectedRows([]);
    // setSelectedRowKey([]);
  };

  const handleChangeMonth = value => {
    const currentMonthName = monthNames[value].label;
    setCurrentMonth(currentMonthName);
    // setSelectedRows([]);
    // setSelectedRowKey([]);
  };

  const yearOptions = [
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    },
    {
      value: 2025,
      label: '2025'
    },
    {
      value: 2026,
      label: '2026'
    }
  ];

  return (
    <>
      {contextHolder}
      <Loading visible={modalVisible} />
      <Card>
        <Card.Header className="bg-shape modal-shape-header px-4 position-relative">
          <div className="position-relative z-index-1 light">
            <h5 className="mb-0 text-white">Accounts Transactions Report</h5>
          </div>
        </Card.Header>
        <Row align="middle">
          <Col style={style} span={23} offset={1}>
            <Space wrap>
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
                &nbsp; &nbsp;
                <Button
                  id="downloadBtn"
                  onClick={downloadTxtFile}
                  value="download"
                >
                  Download Other Bank
                </Button>
                &nbsp; &nbsp;
                <Button id="downloadBtn" onClick={downloadTxt} value="download">
                  Download City Union Bank
                </Button>
              </div>
              <div id="orders-actions">
                <Checkbox onChange={onChangeSalaryPaid} checked={salaryPaid}>
                  Salary Paid
                </Checkbox>
              </div>
              <div id="orders-actions">
                <Checkbox
                  onChange={onChangeSalaryNotPaid}
                  checked={salaryNotPaid}
                >
                  Salary Not Paid
                </Checkbox>
              </div>
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  onClick={handleMarkPaid}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    Mark Paid
                  </span>
                </IconButton>
              </div>
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  onClick={handleUnMarkPaid}
                >
                  <span className="d-none d-sm-inline-block ms-1">
                    Mark Un Paid
                  </span>
                </IconButton>
              </div>
              <div id="orders-actions">
                <Select
                  defaultValue={currentYear}
                  style={{
                    width: 120
                  }}
                  onChange={handleChangeYear}
                  options={yearOptions}
                />
              </div>
              <div id="orders-actions">
                <Select
                  defaultValue={currentMonth}
                  value={currentMonth}
                  style={{
                    width: 120
                  }}
                  onChange={handleChangeMonth}
                  options={getMonthNames(currentMonth)}
                />
              </div>
            </Space>
          </Col>
        </Row>
        <Row>
          <AccountsTransactionListTable
            rowSelection={{
              type: 'checkbox',
              ...rowSelection
            }}
            columns={columns}
            tableData={data}
          />
        </Row>
      </Card>
    </>
  );
};
export default AccountsTransactionList;
