import axios from 'axios';
//import { get } from 'lodash';
import { settings } from 'config';
import { get } from 'lodash';

const postNotification = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/notification`, data, {
    headers: {
      'erp-token': token
    }
  });

const postRole = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/role`, data, {
    headers: {
      'erp-token': token
    }
  });

const getRole = token => {
  return axios.get(`${settings.apiUrl}/api/v1/role/allrole`, {
    headers: {
      'erp-token': token
    }
  });
};

const getAllRoles = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/role?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/role?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const NotificationgetAll = (id, token) => {
  const currentDate = new Date()
    .toISOString()
    .slice(0, 10)
    .split('-')
    .reverse()
    .join('-');
  const [day, month, year] = currentDate.split('-');
  const isoDate = new Date(`${year}-${month}-${day}`).toISOString();
  const filter = { date: isoDate };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/notification?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const deleteRoleById = (id, token) => {
  return axios.delete(`${settings.apiUrl}/api/v1/role/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

export default {
  postNotification,
  NotificationgetAll,
  postRole,
  getRole,
  getAllRoles,
  deleteRoleById
};
