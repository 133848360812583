import axios from 'axios';
import { settings } from 'config';

// const getSalaryBySitecode = (siteId, month, year, token) => {
//   return axios.get(
//     `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`,
//     {
//       headers: {
//         'erp-token': token
//       }
//     }
//   );
// };

const getSalaryBySitecode = async (siteId, month, year, token, status) => {
  let url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`;

  if (status === 'PAID') {
    const filter = { status: 'PAID' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}?filter=${serializedData}`;
  } else if (status === 'NOT_PAID') {
    const filter = { status: 'NOT_PAID' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}?filter=${serializedData}`;
  } else if (status === 'HOLD') {
    const filter = { status: 'HOLD' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}?filter=${serializedData}`;
  } else {
    url = `${settings.apiUrl}/api/v1/salary/${siteId}/${month}/${year}`;
  }

  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getClubedSalaryBySitecode = async (month, year, token) => {
  const filter = { isMultiSite: true };
  const serializedData = JSON.stringify(filter);
  let url = `${settings.apiUrl}/api/v1/salary/ALL/${month}/${year}?filter=${serializedData}`;
  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getInactiveSalaryBySitecode = async (
  siteId,
  month,
  year,
  token,
  salaryPaid,
  salaryNotPaid
) => {
  let url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}`;

  if (salaryPaid) {
    console.log('salaryPaid ');
    const filter = { isSalaryPaid: salaryPaid };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}?filter=${serializedData}`;
  } else if (salaryNotPaid === 'false') {
    const filter = { isSalaryPaid: 'false' };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}?filter=${serializedData}`;
  } else {
    url = `${settings.apiUrl}/api/v1/salary/inactive/${siteId}/${month}/${year}`;
  }

  try {
    return await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getSalaryByEmployee = (employeeId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/byEmployee/${employeeId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateSalaryReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/salary/generateSalaryReport/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateInvoiceReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/invoice/create/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const generateSitePLReportBySiteId = (siteId, month, year, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/profit/lose/generateProfitLoseReport/${siteId}/${month}/${year}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateSalaryReport = (data, status, month, token) =>
  axios.post(
    `${settings.apiUrl}/api/v1/salary/update/byemployee/${month}/${status}`,
    data,
    {
      headers: {
        'erp-token': token
      }
    }
  );

export default {
  getSalaryBySitecode,
  getSalaryByEmployee,
  generateSalaryReportBySiteId,
  generateInvoiceReportBySiteId,
  getInactiveSalaryBySitecode,
  updateSalaryReport,
  getClubedSalaryBySitecode,
  generateSitePLReportBySiteId
};
