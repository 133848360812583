import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const postStoreVendordetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/storevendor`, data, {
    headers: {
      'erp-token': token
    }
  });

const getStoreVendordetails = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/storevendor/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const updateStoreVendordManagementdetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/storevendor/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchStoreVendordManagementData = async (
  page,
  pageSize,
  pageFilter,
  token
) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/storevendor?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/storevendor?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  postStoreVendordetails,
  getStoreVendordetails,
  updateStoreVendordManagementdetails,
  fetchStoreVendordManagementData
};
