import React, { useEffect, useRef, useState } from 'react';
import IconButton from 'components/common/IconButton';
import { Card, Col, Row } from 'react-bootstrap';
import attendanceAPI from 'api/attendance';
import { Spin, Space } from 'antd';
import { get } from 'lodash';
import { useAuth } from 'hooks/useAuth';
import { getDaysInMonth, getPreviousMonthNames } from 'helpers/utils';
import { toast } from 'react-toastify';
import AttendanceReports from './dailyBreakUpAttendance';
import { Select } from 'antd';
const DailyBreakUp = () => {
  const [data, setData] = useState([]);
  const todayDate = new Date();
  const monthNameList = getPreviousMonthNames(todayDate.getFullYear());
  const [monthOptions, setMonthOptions] = useState(monthNameList);
  const [currentMonth, setCurrentMonth] = useState(todayDate.getMonth());
  const [currentYear, setCurrentYear] = useState(todayDate.getFullYear());
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const componentRef = useRef();
  const dayColumn = getDaysInMonth(currentMonth, currentYear);
  const defaultColumn = [
    {
      title: 'Designation',
      dataIndex: 'roleName',
      fixed: 'left',
      key: 'roleName',
      width: 200
    }
  ];

  const totalColumn = [
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      width: 100,
      render: text => <strong>{text}</strong>
    }
  ];

  const siteIdsString = window.localStorage.getItem('siteIds');
  const siteIds = siteIdsString ? JSON.parse(siteIdsString) : [];
  let siteIdsOptions = [];
  siteIds.map(item => {
    const siteIdArray = item.split('-');
    siteIdsOptions.push({
      value: siteIdArray[0],
      label: item
    });
  });
  siteIdsOptions.push({ value: 'ALL', label: 'ALL' });
  let userSiteIds = get(user, 'unitCodes');
  let userDefaultSiteId = '';
  if (userSiteIds && userSiteIds[0] === 'ALL') {
    userDefaultSiteId = get(siteIdsOptions[0], 'value', '');
  } else if (userSiteIds && userSiteIds.length > 0) {
    siteIdsOptions = siteIdsOptions.filter(item =>
      userSiteIds.includes(item.value)
    );
    userDefaultSiteId = userSiteIds[0];
  }
  const [currentSiteId, setCurrentSiteId] = useState(userDefaultSiteId);

  const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
  const [tableColumn, setTableColumn] = useState(columns);
  useEffect(() => {
    const monthNameList = getPreviousMonthNames(currentYear);
    const dayColumn = getDaysInMonth(currentMonth, currentYear);
    const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
    setTableColumn(columns);
    setMonthOptions(monthNameList);
  }, [currentYear]);

  useEffect(() => {
    const dayColumn = getDaysInMonth(currentMonth, currentYear);
    const columns = [...defaultColumn, ...dayColumn, ...totalColumn];
    setTableColumn(columns);
  }, [currentMonth]);

  const getAttendanceReport = (currentMonth, currentYear, currentSiteId) => {
    setLoading(true);
    attendanceAPI
      .getDailyBreakUp(currentMonth + 1, currentYear, currentSiteId)
      .then(response => {
        const todayAttendance = get(response, 'data.data', []);
        setData(todayAttendance);
        setLoading(false);
      })
      .catch(() => {
        toast.error(`Get Attendance failed!`, {
          theme: 'colored'
        });
        setLoading(false);
      });
  };
  useEffect(() => {
    setTimeout(() => {
      getAttendanceReport(currentMonth, currentYear, currentSiteId);
    }, 300);
  }, [currentMonth, currentYear, currentSiteId]);
  if (loading) {
    return (
      <div className="text-center">
        <Spin size="large" />
      </div>
    );
  }
  const style = {
    padding: '8px 0'
  };
  const handleChangeSiteId = value => {
    setCurrentSiteId(value);
  };

  const onSearch = value => {
    console.log('search:', value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  const handleChangeYear = value => {
    if (value === todayDate.getFullYear()) {
      setCurrentMonth(todayDate.getMonth());
    }
    console.log(value);
    setCurrentYear(value);
  };
  const yearOptions = [
    {
      value: 2022,
      label: '2022'
    },
    {
      value: 2023,
      label: '2023'
    },
    {
      value: 2024,
      label: '2024'
    }
  ];
  const handleChangeMonth = value => {
    setCurrentMonth(value);
    console.log(value);
  };
  return (
    <>
      <Card className="mb-3">
        <Card.Body>
          <Row className="justify-content-between align-items-center">
            <Col md>
              <h5 className="mb-2 mb-md-0">INVOICE BREAKUP</h5>
            </Col>
            <Col xs="auto">
              <div id="orders-actions">
                <IconButton
                  variant="primary"
                  size="sm"
                  icon="external-link-alt"
                  transform="shrink-3"
                  // onClick={onClickExportAsExcel}
                >
                  <span className="d-none d-sm-inline-block ms-1">Export</span>
                </IconButton>
              </div>
            </Col>
          </Row>
        </Card.Body>
      </Card>

      <Card className="mb-3" id="pdf-content" ref={componentRef}>
        <Card.Body>
          <Row align="middle">
            <Col style={style} offset={1}>
              <Space wrap>
                <Select
                  defaultValue={currentSiteId}
                  showSearch
                  placeholder="Select a site"
                  optionFilterProp="children"
                  onChange={handleChangeSiteId}
                  onSearch={onSearch}
                  filterOption={filterOption}
                  options={siteIdsOptions}
                  style={{
                    width: 410
                  }}
                />
                <Select
                  defaultValue={currentYear}
                  style={{
                    width: 120
                  }}
                  onChange={handleChangeYear}
                  options={yearOptions}
                />
                <Select
                  defaultValue={currentMonth}
                  value={currentMonth}
                  style={{
                    width: 120
                  }}
                  onChange={handleChangeMonth}
                  options={monthOptions}
                />
              </Space>
            </Col>
          </Row>
          <div className="mt-4 fs--1">
            <Row>
              <AttendanceReports
                columns={tableColumn}
                tableData={data}
                hide={true}
                // month={currentMonth}
                // year={currentYear}
              />
            </Row>
          </div>
        </Card.Body>
        <Card.Footer className="bg-light">
          <p className="fs--1 mb-0"></p>
        </Card.Footer>
      </Card>
    </>
  );
};

export default DailyBreakUp;
