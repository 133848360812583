import http from './uploadClient';

const upload = (file, onUploadProgress) => {
  let formData = new FormData();

  formData.append('file', file);

  return http.post('/api/v1/file/upload', formData, {
    // /api/objects/vipras-erp
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  });
};

const getFiles = () => {
  return http.get('/files');
};

const FileUploadService = {
  upload,
  getFiles
};

export default FileUploadService;
