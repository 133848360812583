import axios from 'axios';
import { settings } from 'config';
import { get } from 'lodash';

const addDocument = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/document`, data, {
    headers: {
      'erp-token': token
    }
  });
const addDmrDocument = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/dmr`, data, {
    headers: {
      'erp-token': token
    }
  });

const getDocumentByFilter = (type, token) => {
  const filter = { bucket: type };
  const serializedData = JSON.stringify(filter);
  return axios.get(
    `${settings.apiUrl}/api/v1/document?filter=${serializedData}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getDmrDocumentByFilter = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  const defaultFilter = { from: null, to: null };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/dmr?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null) ||
    pageFilter.value.trim().length > 1
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    if (pageFilter.value.trim().length > 1) {
      filter = { ...filter, [`${pageFilter.key}`]: pageFilter.value };
    }
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/dmr?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

const getDmrFmDocumentByFilter = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  const defaultFilter = { from: null, to: null };
  const serializedData = JSON.stringify(defaultFilter);
  let url = `${settings.apiUrl}/api/v1/dmr/fm/list?filter=${serializedData}&page=${offset}&limit=${pageSize}`;

  if (
    (pageFilter.from && pageFilter.from !== null) ||
    (pageFilter.to && pageFilter.to !== null)
  ) {
    let filter = {
      from: pageFilter.from,
      to: pageFilter.to
    };
    filter = { ...filter };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/dmr/fm/list?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

// const getDmrFmDocumentByFilter = async (page, pageSize, pageFilter, token) => {
//   const offset = page + 1;
//   let url = `${settings.apiUrl}/api/v1/dmr/fm/list?page=${offset}&limit=${pageSize}`;

//   if (pageFilter.value.trim().length > 1) {
//     const filter = { [`${pageFilter.key}`]: pageFilter.value };
//     const serializedData = JSON.stringify(filter);
//     url = `${settings.apiUrl}/api/v1/dmr/fm/list?filter=${serializedData}`;
//   }

//   try {
//     const response = await axios.get(url, {
//       headers: {
//         'erp-token': token
//       }
//     });
//     const results = get(response, 'data.data', []) || [];

//     const hasError = get(response, 'data.success');

//     const data = {
//       results: results,
//       count: get(response, 'data.data.totalItems'),
//       hasError
//     };
//     return data;
//   } catch (e) {
//     throw new Error(`API error:${e?.message}`);
//   }
// };

export default {
  addDocument,
  getDocumentByFilter,
  addDmrDocument,
  getDmrDocumentByFilter,
  getDmrFmDocumentByFilter
};
