/* eslint-disable prettier/prettier */
/* eslint-disable react/prop-types */
import React from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import CardDropdown from 'components/common/CardDropdown';

import RecentPurchasesHeader from './RecentPurchasesHeader';
import TableData from '../previous-employment/Table';

const RecentPurchases = ({ handleModel, handleValue, selectRowId, updateRecord }) => {
  const columns = [
    {
      accessor: 'name',
      Header: 'Name',
      headerProps: { className: 'pe-1' }
    },
    {
      accessor: 'relation',
      Header: 'Relation',
      headerProps: { className: 'pe-7' }
    },
    {
      accessor: 'dob',
      Header: 'DOB'
    },
    {
      accessor: 'occupation',
      Header: 'Occupation'
    },
    {
      accessor: 'aadharNumber',
      Header: 'AadharNumber'
    },
    {
      accessor: 'isAccepted',
      Header: '',
      disableSortBy: true,
      cellProps: {
        className: 'text-end py-2'
      },
      Cell: ({ row }) => {
        const i = row.original.id;
        const data = row.original;
        return (
          <CardDropdown iconClassName="fs--1" drop="start">
            <div className="py-2">
              <Dropdown.Item
                onClick={() => {
                  editRecord(data);
                }}
                className="text-warning"
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  deleteRecord(i);
                }}
                className="text-danger"
              >
                Delete
              </Dropdown.Item>
            </div>
          </CardDropdown>
        );
      }
    }
  ];

  const deleteRecord = id => {
    selectRowId(id);
  };

  const editRecord = rec => {
    updateRecord(rec);
  };
  return (
    <Card>
      <Card.Header>
        <RecentPurchasesHeader
          table
          handleModel={handleModel}
          pageName="family"
        />
      </Card.Header>

      <Card.Body className="p-0">
        <TableData
          columns={columns}
          data={handleValue}
          headerClassName="bg-200 text-900 text-nowrap align-middle"
          rowClassName="align-middle white-space-nowrap"
          tableProps={{
            bordered: true,
            striped: true,
            className: 'fs--1 mb-0 overflow-hidden'
          }}
        />
      </Card.Body>

      <Card.Footer></Card.Footer>
    </Card>
  );
};

export default RecentPurchases;
