import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const addUsers = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/users`, data, {
    headers: {
      'erp-token': token
    }
  });

const updateUsers = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/users/update/user`, data, {
    headers: {
      'erp-token': token
    }
  });

const getUserdetails = (userId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/users/${userId}`, {
    headers: {
      'erp-token': token
    }
  });
};
const fetchusermanagementData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  const filter = { status: true };
  const serializedData = JSON.stringify(filter);
  let url = `${settings.apiUrl}/api/v1/users?page=${offset}&limit=${pageSize}&filter=${serializedData}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value, status: true };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/users?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  addUsers,
  getUserdetails,
  fetchusermanagementData,
  updateUsers
};
