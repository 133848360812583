import axios from 'axios';
import { get, trim } from 'lodash';
import { settings } from 'config';

const AddSales = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/Sales`, data, {
    headers: {
      'erp-token': token
    }
  });
const getSaledetails = (saleId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/sales/${saleId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getProductdetails = token => {
  // const filter = { [`${pageFilter.key}`]: pageFilter.value };
  // const serializedData = JSON.stringify(filter);
  // const serializedDatas = JSON.stringify('STORE');
  return axios.get(
    `${settings.apiUrl}/api/v1/product?filter=%7B%20%22type%22%3A%20%22STORE%22%7D`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updatesalesdetails = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/sales/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });
const fetchSalesData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/Sales?page=${offset}&limit=${pageSize}`;

  if (trim(pageFilter.value).length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/Sales?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
const AddSaleReturn = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/stockreturn`, data, {
    headers: {
      'erp-token': token
    }
  });
const fetchSaleReturnData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/stockreturn?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/stockreturn?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};
export default {
  AddSales,
  getSaledetails,
  updatesalesdetails,
  fetchSalesData,
  AddSaleReturn,
  fetchSaleReturnData,
  getProductdetails
};
