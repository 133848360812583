import axios from 'axios';
import { settings } from 'config';

const addEmployee = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/employee`, data, {
    headers: {
      'erp-token': token
    }
  });

const updateEmployee = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/employee/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

export default {
  addEmployee,
  updateEmployee
};
