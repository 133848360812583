import axios from 'axios';
import { get, isArray, isEmpty, trim } from 'lodash';
import { settings } from 'config';

const addSite = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/site`, data, {
    headers: {
      'erp-token': token
    }
  });

const addShiftSchedule = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/shift`, data, {
    headers: {
      'erp-token': token
    }
  });

const getShiftScheduleById = (siteId, month, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/shift/by/${siteId}/${month}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getAttendanceById = (siteId, token) => {
  return axios.get(
    `${settings.apiUrl}/api/v1/erp?collection=erp_attendance&field_name=siteId&field_value=${siteId}`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateSite = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/site/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const updateShiftSchedule = (id, data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/shift/${id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getSitedetails = (siteId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/site/${siteId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getSitedetailsBySiteCode = (siteId, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/site/by/${siteId}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getAllSiteIds = token => {
  return axios.get(`${settings.apiUrl}/api/v1/site/allSiteIds`, {
    headers: {
      'erp-token': token
    }
  });
};

const getAllRole = token => {
  return axios.get(`${settings.apiUrl}/api/v1/role/allrole`, {
    headers: {
      'erp-token': token
    }
  });
};

const fetchSiteData = async (page, pageSize, pageFilter, token, siteId) => {
  let isActive = pageFilter.key === 'isActive' ? pageFilter.value : true;
  const offset = page + 1;
  const filter = { isActive };
  const serializedData = JSON.stringify(filter);
  let url = `${settings.apiUrl}/api/v1/site?page=${offset}&limit=${pageSize}&filter=${serializedData}`;

  const unitCodes = isArray(siteId) ? siteId : [];
  if (!isEmpty(unitCodes) && unitCodes[0] !== 'ALL') {
    // const filter = { siteCode: siteId, isActive };
    const filter = { unitcode: { $in: unitCodes }, isActive };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/site?filter=${serializedData}&page=${offset}&limit=${pageSize}`;
  }

  if (trim(pageFilter.value).length > 1) {
    const siteIdNewArray = isArray(siteId) ? siteId : [];
    const finalSiteIds =
      !isEmpty(unitCodes) && unitCodes[0] !== 'ALL' ? siteIdNewArray : [];
    const isAllAccess = !isEmpty(unitCodes) && unitCodes[0] === 'ALL';
    const filter = !isAllAccess
      ? {
          [`${pageFilter.key}`]: pageFilter.value,
          unitcode: { $in: finalSiteIds },
          isActive
        }
      : { [`${pageFilter.key}`]: pageFilter.value, isActive };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/site?filter=${serializedData}&page=${offset}&limit=${pageSize}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  addSite,
  updateSite,
  getSitedetails,
  fetchSiteData,
  getSitedetailsBySiteCode,
  addShiftSchedule,
  getShiftScheduleById,
  updateShiftSchedule,
  getAttendanceById,
  getAllSiteIds,
  getAllRole
};
