import axios from 'axios';
import { get } from 'lodash';
import { settings } from 'config';

const addStock = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/stockmain`, data, {
    headers: {
      'erp-token': token
    }
  });

// Get all the Stocks Details from the API
const getStockdetails = token => {
  return axios.get(`${settings.apiUrl}/api/v1/stockmain`, {
    headers: {
      'erp-token': token
    }
  });
};

// Update All Stocks from the API
const updateStockdetails = (id, data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/stockmain/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const fetchStocksData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/stockmain?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/stockmain?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  addStock,
  getStockdetails,
  updateStockdetails,
  fetchStocksData
};
