// import axios from 'axios';
import { get } from 'lodash';
import axios from 'axios';
import { settings } from 'config';

const addCrm = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/CRM`, data, {
    headers: {
      'erp-token': token
    }
  });

const getByIdCRM = (id, token) => {
  return axios.get(`${settings.apiUrl}/api/v1/CRM/${id}`, {
    headers: {
      'erp-token': token
    }
  });
};

const getByUserDropDown = token => {
  return axios.get(`${settings.apiUrl}/api/v1/users/list/allUserDropdown`, {
    headers: {
      'erp-token': token
    }
  });
};

const getByAuthUserDropDown = token => {
  return axios.get(
    `${settings.apiUrl}/api/v1/users/list/allUserDropdown/authOfficer`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const getBySubcontractorUserDropDown = token => {
  return axios.get(
    `${settings.apiUrl}/api/v1/users/list/allUserDropdown/subContractor`,
    {
      headers: {
        'erp-token': token
      }
    }
  );
};

const updateCRM = (data, token) =>
  axios.post(`${settings.apiUrl}/api/v1/CRM/${data.id}`, data, {
    headers: {
      'erp-token': token
    }
  });

const getCrm = token =>
  axios.get(`${settings.apiUrl}/api/v1/CRM`, {
    headers: {
      'erp-token': token
    }
  });
const fetchSaleReturnData = async (page, pageSize, pageFilter, token) => {
  const offset = page + 1;
  let url = `${settings.apiUrl}/api/v1/CRM?page=${offset}&limit=${pageSize}`;

  if (pageFilter.value.trim().length > 1) {
    const filter = { [`${pageFilter.key}`]: pageFilter.value };
    const serializedData = JSON.stringify(filter);
    url = `${settings.apiUrl}/api/v1/CRM?filter=${serializedData}`;
  }

  try {
    const response = await axios.get(url, {
      headers: {
        'erp-token': token
      }
    });
    const results = get(response, 'data.data.items', []) || [];

    const hasError = get(response, 'data.success');

    const data = {
      results: results,
      count: get(response, 'data.data.totalItems'),
      hasError
    };
    return data;
  } catch (e) {
    throw new Error(`API error:${e?.message}`);
  }
};

export default {
  addCrm,
  fetchSaleReturnData,
  getCrm,
  updateCRM,
  getByIdCRM,
  getByUserDropDown,
  getByAuthUserDropDown,
  getBySubcontractorUserDropDown
};
